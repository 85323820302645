import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import Select from 'components/forms/elements/select'
import Textarea from 'components/forms/elements/textarea'
import { Column, Grid } from 'components/grid'

const StepThree = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Equipment Model'
            name='text'
            required
          />
        </Column>
        <Column columns='1/3'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Serial Number'
            name='Serial Number'
            required
          />
        </Column>
        <Column columns='1/3'>
          <Select updateValidationSet={updateValidationSet} name='Status' label='Status' required>
            <option value='' disabled>
              Select an option
            </option>
            <option value='I have this item sold and need it'>
              I have this item sold and need it
            </option>
            <option value='I have a customer interested in this item'>
              I have a customer interested in this item
            </option>
            <option value='I am inquiring on the availability of this item'>
              I am inquiring on the availability of this item
            </option>
          </Select>
        </Column>
      </Grid>
      <Textarea
        updateValidationSet={updateValidationSet}
        label='Message'
        name='Message'
        validationError='Please enter a valid message.'
      />
    </>
  )
}

StepThree.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepThree
