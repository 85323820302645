import React from 'react'
import MultiStepForm from 'components/forms/multi-step-form'
import StepOne from './step-one'
import StepTwo from './step-two'
import StepThree from './step-three'

const steps = [
  { name: 'About You', renderComponent: props => <StepOne {...props} /> },
  { name: 'Dealership Info', renderComponent: props => <StepTwo {...props} /> },
  { name: 'Equipment Info', renderComponent: props => <StepThree {...props} /> },
]

const TransferForm = () => {
  return <MultiStepForm steps={steps} name='Equipment Transfer' />
}

export default TransferForm
