import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import Email from 'components/forms/elements/email'
import Phone from 'components/forms/elements/phone'
import { Column, Grid } from 'components/grid'

const StepOne = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='First Name'
            name='First Name'
            validationError='Please enter a valid first name.'
            required
          />
        </Column>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Last Name'
            name='Last Name'
            validationError='Please enter a valid last name.'
            required
          />
        </Column>
      </Grid>
      <Grid>
        <Column columns='1/2'>
          <Email updateValidationSet={updateValidationSet} required />
        </Column>
        <Column columns='1/2'>
          <Phone updateValidationSet={updateValidationSet} required />
        </Column>
      </Grid>
    </>
  )
}

StepOne.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepOne
