import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import State from 'components/forms/elements/state'
import { Column, Grid } from 'components/grid'

const StepTwo = ({ updateValidationSet }) => {
  return (
    <>
      <Grid>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Your Dealership'
            name='Dealership'
            required
          />
        </Column>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Dealership Account Number'
            name='Account Number'
            required
          />
        </Column>
      </Grid>
      <Grid>
        <Column columns='1/2'>
          <Input
            updateValidationSet={updateValidationSet}
            label='Dealership City'
            name='Dealership City'
            required
          />
        </Column>
        <Column columns='1/2'>
          <State updateValidationSet={updateValidationSet} required />
        </Column>
      </Grid>
    </>
  )
}

StepTwo.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepTwo
